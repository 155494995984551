import React from 'react';
import { DateTime } from 'luxon';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ISignCreateRequest } from '@/types/sign';
import { IFileResponse } from '@/types/file';
import { IANA_TO_READABLE_TIMEZONES_DICT } from '@/utils/constants';
import FileWithPreview from '@/common/FileWithPreview/FileWithPreview';
import FileDetailsBadges from '@/common/FileDetailsBadges';

interface AddSignPreviewProps {
  sign: ISignCreateRequest,
  selectedFile: IFileResponse | null,
}

const AddSignPreview = ({
  sign,
  selectedFile,
}: AddSignPreviewProps) => {
  return (
    <div>
      <section className="bg-white shadow-sm">
        {(selectedFile) && (
          <div className="bg-dark d-flex justify-content-center text-white">
            <FileWithPreview
              file={selectedFile}
            />
          </div>
        )}
        <div className="p-3">
          <h3 className="mb-1 text-truncate">
            Default Content
          </h3>
          <p>
            <small>Will play if no other active content is scheduled</small>
          </p>
          {(selectedFile) && (
            <div>
              {selectedFile.width} x {selectedFile.height}<br />
              <FileDetailsBadges file={selectedFile} />
            </div>
          )}
        </div>
      </section>

      <section className="mt-2 p-3 bg-white shadow-sm">
        <h3 className="mb-1 text-truncate">
          Sign Details
        </h3>
        <div>
          {sign.width} x {sign.height}
          {/* <br />
          <small className="text-muted">{sign.aspectRatio} aspect ratio</small> */}
        </div>
        <div className="mt-3">
          <FontAwesomeIcon icon="location-dot" />
          <span className="ms-2">
            { sign.address_1 } { sign.address_2 } { sign.city }, { sign.state }
          </span>
        </div>
        <hr />
        <table className="key-value-table">
          <tbody>
            <tr>
              <td>Sign Active</td>
              <td>{DateTime.fromISO(sign.activeStartTime).toFormat('hh:mm a')} - {DateTime.fromISO(sign.activeEndTime).toFormat('hh:mm a')}</td>
            </tr>
            <tr>
              <td>Manufacturer</td>
              <td>{ sign.manufacturer }</td>
            </tr>
            <tr>
              <td>Sign Timezone</td>
              <td>{ IANA_TO_READABLE_TIMEZONES_DICT[sign.timezone] || sign.timezone }</td>
            </tr>
            <tr>
              <td>FEMA/IPAWS alerts</td>
              <td>{ sign.alertsEnabled ? 'Enabled' : 'Disabled' }</td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  );
};

export default AddSignPreview;
