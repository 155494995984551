import React, { useEffect } from 'react';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/esm/Button';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { DateTime } from 'luxon';

import { useCreateSignRegistrationKey, useDeleteOneSignRegistrationKey, useReadOneSign, useReadSignImpressionsReport } from '@/hooks/sign';
import { IANA_TO_READABLE_TIMEZONES_DICT } from '@/utils/constants';
import Loader from '@/common/Loader';
import SignPreviewModal from '@/features/Schedule/components/SignPreviewModal';
import { useToggle } from '@/hooks';
import CustomIcon from '@/common/CustomIcon';
import useMyUser from '@/context/myUser/useMyUser';
import { SUPER_ADMIN_ROLE } from '@/constants';
import SignRegistrationTable from '@/features/Signs/components/SignRegistrationTable';
import SignOnlineStatusBadge from '@/features/Signs/components/signOnlineStatusBadge/SignOnlineStatusBadge';
import SignAnalyticsModal from '@/features/Signs/components/SignAnalyticsModal/SignAnalyticsModal';

const ViewSign = () => {
  const routerNavigate = useNavigate();
  const { signId: routerSignId } = useParams();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirectUrl');
  const { show: showSignPreviewModal, hide: hideSignPreviewModal, isVisible: isSignPreviewModalVisible } = useToggle();
  const { show: showSignAnalyticsModal, hide: hideSignAnalyticsModal, isVisible: isSignAnalyticsModalVisible } = useToggle();
  const signId = routerSignId ? parseInt(routerSignId, 10) : null;

  const { myUser } = useMyUser();

  const {
    readOneSign,
    sign,
    readOneSignIsLoading,
  } = useReadOneSign();

  const {
    createSignRegistrationKey,
    createdSignRegistrationKey,
    createSignRegistrationKeyIsLoading,
  } = useCreateSignRegistrationKey();

  const {
    deleteOneSignRegistrationKey,
    deletedSignRegistrationKey,
    deleteOneSignRegistrationKeyIsLoading,
  } = useDeleteOneSignRegistrationKey();

  const {
    readSignImpressionReport,
    readSignImpressionReportIsLoading,
    fileDownloaded,
  } = useReadSignImpressionsReport();

  useEffect(() => {
    if (!fileDownloaded) return;

    hideSignAnalyticsModal();
  }, [fileDownloaded]);

  useEffect(() => {
    if (!signId) return;
    readOneSign(signId);
  }, [signId]);

  useEffect(() => {
    if ((createdSignRegistrationKey || deletedSignRegistrationKey) && signId) {
      readOneSign(signId);
    }
  }, [createdSignRegistrationKey, deletedSignRegistrationKey]);

  const addRegistrationKey = () => {
    if (!sign) return;
    createSignRegistrationKey(sign.id);
  };

  const removeRegistrationKey = (registrationId: number) => {
    if (!sign) return;
    deleteOneSignRegistrationKey(registrationId, sign.id);
  };

  const navigateBack = () => {
    if (redirectUrl) {
      window.location.href = redirectUrl;
      return;
    }

    routerNavigate(-1);
  };

  if (readOneSignIsLoading) {
    return (<div className="text-center px-5 py-3">
      <Loader size = "2x" />
    </div>);
  }

  if (!sign) return null;

  return (
    <div>
      {/* header */}
      <div className="px-3 py-4 bg-white shadow-sm">
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center flex-grow-1 text-truncate">
            <div className="border border-dark rounded-circle p-2">
              <CustomIcon
                type="sign"
              />
            </div>
            <span className="ms-2 d-inline-block h5 mb-0 text-truncate">{ sign.name }</span>
          </div>

          <div className="d-flex align-items-center">
            <SignOnlineStatusBadge
              sign={sign}
            />
            <Dropdown>
              <Dropdown.Toggle
                className="text-dark"
                as={Button}
                variant="link"
              >
                <FontAwesomeIcon icon="ellipsis-v" />
              </Dropdown.Toggle>

              <Dropdown.Menu align="end">
                <Dropdown.Item onClick={() => routerNavigate('./edit')}>Edit</Dropdown.Item>
                <Dropdown.Item onClick={showSignAnalyticsModal}>Analytics</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="mt-3">
          <Button variant="outline-primary"
            onClick={showSignPreviewModal}
          >
            Sign Preview
          </Button>
          <Link
            className="ms-2"
            to={`/schedule/signs/${sign.id}`}
          >
            <Button
              variant="outline-primary"
            >
              Schedule
            </Button>
          </Link>
        </div>
      </div>
      {/* main content */}
      <div className="mt-2 px-3 py-4 bg-white shadow-sm">
        <div>
          {sign.width} x {sign.height} <br />
          <small className="text-muted">{sign.aspectRatio} aspect ratio</small>
        </div>
        <div className="mt-3">
          <FontAwesomeIcon icon="location-dot" />
          <span className="ms-2">
            { sign.address_1 } { sign.address_2 } { sign.city }, { sign.state } { sign.zip }
          </span>
        </div>
      </div>
      {/* key value content */}
      <div className="mt-2 px-3 py-4 bg-white shadow-sm">
        <div>
          <table className="key-value-table">
            <tbody>
              <tr>
                <td>Sign Active</td>
                <td>{DateTime.fromISO(sign.activeStartTime).toFormat('hh:mm a')} - {DateTime.fromISO(sign.activeEndTime).toFormat('hh:mm a')}</td>
              </tr>
              <tr>
                <td>Manufacturer</td>
                <td>{ sign.manufacturer }</td>
              </tr>
              <tr>
                <td>Sign Timezone</td>
                <td>{ IANA_TO_READABLE_TIMEZONES_DICT[sign.timezone] || sign.timezone }</td>
              </tr>
              <tr>
                <td>Emergency Alerts</td>
                <td>{ sign.alertsEnabled ? 'Enabled' : 'Disabled' }</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* registration keys */}
      {myUser?.role === SUPER_ADMIN_ROLE && (
        <div className="bg-white shadow-sm mt-2 px-3 py-3">
          <h2 className="h5">
            Registration Keys
          </h2>
          <SignRegistrationTable
            registrationKeys={sign.registrationKeys || []}
            addRegistrationKey={addRegistrationKey}
            isAddSubmitting={createSignRegistrationKeyIsLoading}
            deleteRegistrationKey={(registrationId: number) => removeRegistrationKey(registrationId)}
            isDeleteSubmitting={deleteOneSignRegistrationKeyIsLoading}
          />
        </div>
      )}

      {(redirectUrl) && (
        <section
          className="bg-white shadow-sm p-3 mt-3 d-flex justify-content-end border-top sticky-bottom"
        >
          <Button
            variant="light"
            onClick={navigateBack}
          >
            Go Back
          </Button>
        </section>
      )}

      <SignPreviewModal
        signId={sign.id}
        isVisible={isSignPreviewModalVisible}
        onHide={hideSignPreviewModal}
      />

      <SignAnalyticsModal
        isVisible={isSignAnalyticsModalVisible}
        isSubmitting={readSignImpressionReportIsLoading}
        onHide={hideSignAnalyticsModal}
        onSubmit={(values) => {
          if (!signId) return;

          readSignImpressionReport(signId, {
            type: values.type,
            startDate: values.startDate.toFormat('yyyy-MM-dd'),
            endDate: values.endDate.toFormat('yyyy-MM-dd'),
          });
        }}
      />
    </div>
  );
};

export default ViewSign;
