/* eslint-disable complexity */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { Formik, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import FloatingLabel from 'react-bootstrap/esm/FloatingLabel';
import Form from 'react-bootstrap/esm/Form';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Button from 'react-bootstrap/esm/Button';

import { IFileResponse, IFileUploadForm } from '@/types/file';
import { IUpdateSignForm } from '@/features/Signs/types';

import { useToggle } from '@/hooks';
import { useDeleteOneSign, useReadOneSign, useUpdateOneSign } from '@/hooks/sign';

import { formatTimeZoneToIANA, formatTimeZoneToReadable } from '@/utils/helpers';
import { READABLE_TIMEZONES_OPTIONS, DATETIME_FORMAT } from '@/utils/constants';
import { EMPTY_SIGN_FORM } from '@/features/Signs/constants';
import { USER_ROLE } from '@/constants';

import ConfirmationModal from '@/common/ConfirmationModal';
import Loader from '@/common/Loader';
import useMyUser from '@/context/myUser/useMyUser';

import EditSignDefaultContent from './components/defaultContent/EditSignDefaultContent';

const NAVIGATE_TO_REDIRECT_DELAY_IN_MILLISECONDS = 4000;

const EditSign = () => {
  const routerNavigate = useNavigate();
  const { signId: routerSignId } = useParams();
  const signId = routerSignId ? parseInt(routerSignId, 10) : null;

  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirectUrl');

  const { myUser } = useMyUser();

  const {
    readOneSign,
    sign,
    readOneSignIsLoading,
  } = useReadOneSign();

  const {
    updateOneSign,
    updateOneSignIsLoading,
    updatedSign,
  } = useUpdateOneSign();

  const {
    deleteOneSign,
    deletedSign,
  } = useDeleteOneSign();

  const [defaultFileId, setDefaultFileId] = useState<number | null>(null);

  const { show: showConfirmSignDeleteModal, hide: hideConfirmSignDeleteModal, isVisible: isConfirmSignDeleteModalVisible } = useToggle();

  const navigateBack = () => {
    if (redirectUrl) {
      window.location.href = redirectUrl;
      return;
    }

    routerNavigate(-1);
  };

  useEffect(() => {
    if (signId) readOneSign(signId);
  }, [signId]);

  useEffect(() => {
    if (!updatedSign) return;

    if (redirectUrl) {
      toast(
        `You are being navigated to ${redirectUrl}`,
        {
          type: 'info',
        },
      );
      setTimeout(() => {
        window.location.href = redirectUrl;
      }, NAVIGATE_TO_REDIRECT_DELAY_IN_MILLISECONDS);
      return;
    }

    routerNavigate(`/signs/${signId}`);
  }, [updatedSign]);

  useEffect(() => {
    if (!deletedSign) return;
    hideConfirmSignDeleteModal();
    routerNavigate('/signs');
  }, [deletedSign]);

  const onChangeDefaultContent = (typeOfDefault: 'custom' | 'system', defaultFile: IFileUploadForm | IFileResponse) => {
    if ('id' in defaultFile) setDefaultFileId(defaultFile.id);
  };

  const initialSign: IUpdateSignForm = sign ? {
    id: sign.id,
    name: sign.name,
    activeStartTime: DateTime.fromFormat(sign.activeStartTime, DATETIME_FORMAT),
    activeEndTime: DateTime.fromFormat(sign.activeEndTime, DATETIME_FORMAT),
    width: sign.width,
    height: sign.height,
    aspectRatio: sign.aspectRatio,
    manufacturer: sign.manufacturer,
    address_1: sign.address_1,
    address_2: sign.address_2,
    city: sign.city,
    state: sign.state,
    zip: sign.zip,
    country: sign.country,
    defaultFileId: sign.defaultFileId,
    alertsEnabled: sign.alertsEnabled,
    timezone: formatTimeZoneToReadable(sign.timezone),
  } : {
    ...EMPTY_SIGN_FORM,
    id: 1,
    defaultFileId: 0,
  };

  const schema = Yup.object().shape({
    address_1: Yup.string().required('Please enter sign address'),
    city: Yup.string().required('Please enter city'),
    country:  Yup.string().required('Please enter country'),
    height: Yup.number().typeError('Value must be a number').required('Please enter pixel height'),
    manufacturer: Yup.string().required('Please enter sign manufacturer'),
    name: Yup.string().required('Please enter sign name'),
    state: Yup.string().required('Please enter state'),
    timezone: Yup.string().required('Please enter timezone'),
    width: Yup.number().typeError('Value must be a number').required('Please enter pixel width'),
    zip: Yup.string().required('Please enter zip code'),
  });

  const onFormSubmit = (values: IUpdateSignForm) => {
    // save custom file if it's new
    // use newly saved file as the defaultFileId
    const signToUpdate = {
      ...values,
      height: values.height || 0,
      width: values.width || 0,
      activeStartTime: values.activeStartTime.toFormat(DATETIME_FORMAT),
      activeEndTime: values.activeEndTime.toFormat(DATETIME_FORMAT),
      defaultFileId: defaultFileId || values.defaultFileId,
      timezone: formatTimeZoneToIANA(values.timezone),
    };

    updateOneSign(signToUpdate);
  };

  if (readOneSignIsLoading) return <Loader size="2x" />;

  if (!sign) {
    return <div>Invalid Sign or Insufficient Permissions.</div>;
  }

  return (
    <>
      <Formik
        initialValues={initialSign}
        onSubmit={(values) => {
          onFormSubmit(values);
        }}
        validationSchema={schema}
      >
        {({
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }: FormikProps<IUpdateSignForm>) => {
          return (<Form
            id="edit-sign-form"
            noValidate
            onSubmit={handleSubmit}
          >
            <h1 className="h4 bg-white shadow-sm p-3">
              Edit Sign
            </h1>

            <div className="bg-white shadow-sm mt-2 p-3">
              <Form.Group>
                <FloatingLabel
                  label="Sign Name"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.name && !errors.name}
                    isInvalid={!!errors.name && touched.name}
                  />
                  <ErrorMessage name="name">{(msg) => <span className="text-danger">{msg}</span>}</ErrorMessage>
                </FloatingLabel>
              </Form.Group>
              <Row>
                <Col sm={6} md={3}>
                  <Form.Group className="mb-3">
                    <FloatingLabel label="Pixel Width">
                      <Form.Control
                        type="number"
                        name="width"
                        value={values.width || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.width && !errors.width}
                        isInvalid={!!errors.width && touched.width}
                      />
                    </FloatingLabel>
                    <ErrorMessage name="width">{(msg) => <span className="text-danger">{msg}</span>}</ErrorMessage>
                  </Form.Group>
                </Col>
                <Col sm={6} md={3}>
                  <Form.Group className="mb-3">
                    <FloatingLabel label="Pixel Height">
                      <Form.Control
                        type="number"
                        name="height"
                        value={values.height || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.height && !errors.height}
                        isInvalid={!!errors.height && touched.height}
                      />
                    </FloatingLabel>
                    <ErrorMessage name="height">{(msg) => <span className="text-danger">{msg}</span>}</ErrorMessage>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3">
                    <FloatingLabel label="Manufacturer">
                      <Form.Control
                        type="string"
                        name="manufacturer"
                        value={values.manufacturer}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.manufacturer && !errors.manufacturer}
                        isInvalid={!!errors.manufacturer && touched.manufacturer}
                      />
                    </FloatingLabel>
                    <ErrorMessage name="manufacturer">{(msg) => <span className="text-danger">{msg}</span>}</ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <div className="bg-white shadow-sm mt-2 p-3">
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <FloatingLabel label="Active Start Time">
                      <Form.Control
                        type="time"
                        name="activeStartTime"
                        value={values.activeStartTime.toFormat('T')}
                        onChange={(e: any) => {
                          const dailyStartTime = DateTime.fromISO(e.target.value);
                          setFieldValue('activeStartTime', dailyStartTime);
                        }}
                        max={values.activeEndTime?.toFormat('T')}
                        onBlur={handleBlur}
                        isInvalid={values.activeStartTime.toMillis() >= values.activeEndTime.toMillis()}
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <FloatingLabel label="Active End Time">
                      <Form.Control
                        type="time"
                        name="activeEndTime"
                        value={values.activeEndTime.toFormat('T')}
                        onChange={(e: any) => {
                          const dailyEndTime = DateTime.fromISO(e.target.value);
                          setFieldValue('activeEndTime', dailyEndTime);
                        }}
                        min={values.activeStartTime?.toFormat('T')}
                        isInvalid={values.activeStartTime.toMillis() >= values.activeEndTime.toMillis()}
                        onBlur={handleBlur}
                      />
                    </FloatingLabel>
                    <Form.Control.Feedback type="invalid">
                        Start Time must be before end time
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="mb-3">
                <FloatingLabel label="Timezone">
                  <Form.Select
                    name="timezone"
                    value={values.timezone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.timezone && !errors.timezone}
                    isInvalid={!!errors.timezone && touched.timezone}
                  >
                    {READABLE_TIMEZONES_OPTIONS.map((z) => <option key={z} value={z}>{z}</option>)}
                  </Form.Select>
                </FloatingLabel>
                <ErrorMessage name="timezone">{(msg) => <span className="text-danger">{msg}</span>}</ErrorMessage>
              </Form.Group>
            </div>

            <div className="bg-white shadow-sm mt-2 p-3">
              <Row>
                <Col sm={8}>
                  <Form.Group className="mb-3">
                    <FloatingLabel label="Address">
                      <Form.Control
                        type="string"
                        name="address_1"
                        value={values.address_1}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.address_1 && !errors.address_1}
                        isInvalid={!!errors.address_1 && touched.address_1}
                      />
                    </FloatingLabel>
                    <ErrorMessage name="address_1">{(msg) => <span className="text-danger">{msg}</span>}</ErrorMessage>
                  </Form.Group>
                </Col>
                <Col >
                  <Form.Group className="mb-3">
                    <FloatingLabel label="Address (Cont.)">
                      <Form.Control
                        type="string"
                        name="address_2"
                        value={values.address_2 || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <Form.Group className="mb-3">
                    <FloatingLabel label="City">
                      <Form.Control
                        type="string"
                        name="city"
                        value={values.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.city && !errors.city}
                        isInvalid={!!errors.city && touched.city}
                      />
                    </FloatingLabel>
                    <ErrorMessage name="city">{(msg) => <span className="text-danger">{msg}</span>}</ErrorMessage>
                  </Form.Group>
                </Col>
                <Col xs={6} sm={4}>
                  <Form.Group className="mb-3">
                    <FloatingLabel label="State">
                      <Form.Control
                        type="string"
                        name="state"
                        value={values.state}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.state && !errors.state}
                        isInvalid={!!errors.state && touched.state}
                      />
                    </FloatingLabel>
                    <ErrorMessage name="state">{(msg) => <span className="text-danger">{msg}</span>}</ErrorMessage>
                  </Form.Group>
                </Col>
                <Col xs={6} sm={4}>
                  <Form.Group className="mb-3">
                    <FloatingLabel label="Zip Code">
                      <Form.Control
                        type="string"
                        name="zip"
                        value={values.zip}
                        onChange={(e:any) => {
                          const zipToString = e.target.value.toString();
                          setFieldValue('zip', zipToString);
                        }}
                        onBlur={handleBlur}
                        isValid={touched.zip && !errors.zip}
                        isInvalid={!!errors.zip && touched.zip}
                      />
                    </FloatingLabel>
                    <ErrorMessage name="zip">{(msg) => <span className="text-danger">{msg}</span>}</ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <FloatingLabel label="Country">
                      <Form.Control
                        type="string"
                        name="country"
                        value={values.country}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.country && !errors.country}
                        isInvalid={!!errors.country && touched.country}
                      />
                    </FloatingLabel>
                    <ErrorMessage name="country">{(msg) => <span className="text-danger">{msg}</span>}</ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>
            </div>

            {myUser?.role !== USER_ROLE && (
              <div className="bg-white shadow-sm mt-2 p-3">
                <h2 className="h5">
                  Emergency Alerts
                </h2>
                <Form.Group className="mb-3">
                  <Form.Check
                    id="alerts-enabled"
                    name="alertsEnabled"
                    label="Enable FEMA/IPAWS alerts"
                    checked={values.alertsEnabled}
                    onChange={handleChange}
                  />
                  <p className="mt-2 text-gray small">
                    Enabling this feature will add FEMA alerts to your sign when available. The alert will play between each of your regularly scheduled content.
                  </p>
                  <p className="mt-2 text-gray small">
                    Different kinds of alerts may come through. For example, severe weather, evacuation notices, and missing persons alerts.
                  </p>
                </Form.Group>
              </div>
            )}
          </Form>);
        }}
      </Formik>

      {myUser?.role !== USER_ROLE && (
        <div className="bg-white shadow-sm mt-2 p-3">
          <h2 className="h5">
            Default Content
          </h2>
          <EditSignDefaultContent
            file={sign.defaultFile}
            systemFiles={sign.systemDefaultFiles}
            onChange={onChangeDefaultContent}
          />
        </div>
      )}

      {myUser?.role !== USER_ROLE && (
        <div className="d-flex justify-content-end mt-3 px-3 py-2">
          <Button
            className="w-100"
            variant="danger"
            onClick={showConfirmSignDeleteModal}
          >
            Delete Sign
          </Button>
        </div>
      )}

      <section
        className="bg-white shadow-sm p-3 mt-3 d-flex justify-content-end border-top sticky-bottom"
      >
        <Button
          variant="light"
          onClick={navigateBack}
          disabled={updateOneSignIsLoading}
        >
          Cancel
        </Button>

        <Button
          className="ms-2"
          form="edit-sign-form"
          type="submit"
          variant="primary"
          disabled={updateOneSignIsLoading}
        >
          {(updateOneSignIsLoading)
            ? (<FontAwesomeIcon
              icon="circle-notch"
              spin
            />)
            : 'Submit'
          }
        </Button>
      </section>

      <ConfirmationModal
        show={isConfirmSignDeleteModalVisible}
        title={'Delete Sign'}
        onHide={hideConfirmSignDeleteModal}
        onConfirmation={() => deleteOneSign(sign.id)}
        confirmButtonText={'Delete'}
        confirmButtonColor={'danger'}
      >
        Are you sure you want to permanently delete this sign?
      </ConfirmationModal>
    </>
  );
};

export default EditSign;
