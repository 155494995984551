/* eslint-disable complexity */
import React, { useEffect, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/esm/Modal';
import Tab from 'react-bootstrap/esm/Tab';
import Nav from 'react-bootstrap/esm/Nav';

import { useReadOneSign, useRefreshOneSignSchedule } from '@/hooks/sign';
import { useToggle } from '@/hooks';
import { ISignPreviewScheduleEntity } from '@/types/sign';
import { pad } from '@/utils/helpers';
import { ONE_SECOND_MS, IANA_TO_READABLE_TIMEZONES_DICT } from '@/utils/constants';
import { SUPER_ADMIN_ROLE } from '@/constants';
import Loader from '@/common/Loader';
import BaseTimePicker from '@/common/BaseTimePicker';
import FileWithPreview, { FileWithPreviewHandle } from '@/common/FileWithPreview/FileWithPreview';
import '@/features/Schedule/Schedule.scss';
import useMyUser from '@/context/myUser/useMyUser';
import classNames from 'classnames';
import { IFileResponse } from '@/types/file';

interface PreviewTime {
  hour: number,
  minute: number,
  startAt: 'beginning' | 'end'
}

const DEFAULT_TIME_INPUT = '00:00';
const HOURS_IN_DAY = 24;
const THIRTY_MINUTE_INTERVAL = 30;
const TWELVE_HOURS = 12;
const MINUTES_IN_HOUR = 60;
const DEFAULT_IMAGE_DURATION_S = 8;
const DEFAULT_IMAGE_DURATION_MS = DEFAULT_IMAGE_DURATION_S * ONE_SECOND_MS;

interface SignPreviewModalProps {
  signId: number,
  isVisible?: boolean,
  onHide: () => void,
}

const SignPreview = ({
  signId,
  isVisible = false,
  onHide,
}: SignPreviewModalProps) => {
  const routerNavigate = useNavigate();

  const { myUser } = useMyUser();

  const [videoIndex, setVideoIndex] = useState(0);
  const [activeTabKey, setActiveTabKey] = useState('summary');
  const [isPaused, setIsPaused] = useState(false);
  const [playContentTimer, setPlayContentTimer] = useState<ReturnType<typeof setTimeout> | null>(null);

  const { show: showTimePicker, hide: hideTimePicker, isVisible: isTimePickerVisible } = useToggle();
  const [previewTime, setPreviewTime] = useState<PreviewTime>({ hour: 0, minute: 0, startAt: 'beginning' });
  const [timeInput, setTimeInput] = useState(DEFAULT_TIME_INPUT);
  const [previewStartTime, setPreviewStartTime] = useState(DEFAULT_TIME_INPUT);
  const [previewContents, setPreviewContents] = useState<ISignPreviewScheduleEntity[]>([]);

  const fileWithPreviewRef = useRef<FileWithPreviewHandle>(null);

  const {
    readOneSign,
    sign,
    readOneSignIsLoading,
  } = useReadOneSign();
  const {
    refreshOneSignSchedule,
    refreshScheduleCount,
    refreshOneSignScheduleIsLoading,
  } = useRefreshOneSignSchedule();

  const getNextMinute = (previewTimeVal: PreviewTime) => {
    const nextHour = previewTimeVal.hour + 1;
    const nextMinute = previewTimeVal.minute + 1;

    return {
      hour: nextMinute > MINUTES_IN_HOUR ? nextHour : previewTime.hour,
      minute: nextMinute > MINUTES_IN_HOUR ? 0 : nextMinute,
      startAt: previewTimeVal.startAt,
    };
  };

  const getPreviousMinute = (previewTimeVal: PreviewTime) => {
    const previousHour = previewTimeVal.hour - 1;
    const previousMinute = previewTimeVal.minute - 1;

    return {
      hour: previousMinute < 0 ? previousHour : previewTimeVal.hour,
      minute: previousMinute < 0 ? MINUTES_IN_HOUR - 1 : previousMinute,
      startAt: previewTimeVal.startAt,
    };
  };

  const playNextVideo = (force?: boolean) => {
    if (force) setIsPaused(false);
    const doNotPlayNext = (isPaused && !force) || (typeof videoIndex !== 'number' || previewContents.length <= 0);
    if (doNotPlayNext) return;

    const nextVideoIndex = videoIndex + 1;
    const maxVideoIndex = previewContents.length - 1;
    if (nextVideoIndex <= maxVideoIndex) {
      setVideoIndex(nextVideoIndex);
    } else {
      const nextPreviewMinute = getNextMinute({ ...previewTime, startAt: 'beginning' });
      setPreviewTime(nextPreviewMinute);
    }
  };

  const playPreviousVideo = (force?: boolean) => {
    if (force) setIsPaused(false);
    if (isPaused) return;

    const previousVideoIndex = videoIndex - 1;
    if (previousVideoIndex >= 0) {
      setVideoIndex(previousVideoIndex);
    } else {
      const previousPreviewMinute = getPreviousMinute({ ...previewTime, startAt: 'end' });
      setPreviewTime(previousPreviewMinute);
    }
  };

  const stopContentTimer = () => {
    if (playContentTimer) {
      clearTimeout(playContentTimer);
      setPlayContentTimer(null);
    }
  };

  const pausePlayContent = () => {
    const previewVideoEl = fileWithPreviewRef && fileWithPreviewRef.current && fileWithPreviewRef.current.videoHandle
      ? fileWithPreviewRef.current.videoHandle
      : null;

    const pausePlayVideo = () => {
      if (!previewVideoEl) return;
      if (previewVideoEl.paused) {
        previewVideoEl.play();
      } else {
        previewVideoEl.pause();
      }
    };

    const pausePlayImage = () => {
      if (isPaused) {
        playNextVideo(true);
      } else {
        stopContentTimer();
      }
    };
    setIsPaused(!isPaused);
    if (previewVideoEl) return pausePlayVideo();
    return pausePlayImage();
  };

  const getReadableTimeFromPreviewTime = (previewTimeInput: PreviewTime) => {
    const { hour, minute } = previewTimeInput;
    let newHour = hour;
    const newMinute = minute < THIRTY_MINUTE_INTERVAL ? 0 : THIRTY_MINUTE_INTERVAL;

    let suffix = 'AM';
    if (newHour === TWELVE_HOURS) suffix = 'PM';

    if (newHour > TWELVE_HOURS) {
      newHour = newHour - TWELVE_HOURS;
      suffix = 'PM';
    }

    return `${pad(newHour)}:${pad(newMinute)} ${suffix}`;
  };

  const thirtyMinutesFromPreviewTime = (previewTimeInput: PreviewTime) => {
    const firstThirtyMins = previewTimeInput.minute < THIRTY_MINUTE_INTERVAL;

    const newMinutes = firstThirtyMins ? THIRTY_MINUTE_INTERVAL : 0;
    let newHour = previewTimeInput.hour;
    if (!firstThirtyMins) {
      newHour = previewTimeInput.hour + 1 >= HOURS_IN_DAY ? 0 : previewTimeInput.hour + 1;
    }

    return getReadableTimeFromPreviewTime({ hour: newHour, minute: newMinutes, startAt: 'beginning' });
  };

  const getPreviewTimeFromTimeInput = (inputVal: string): PreviewTime => {
    const [inputHour, inputMinute] = inputVal.split(':');
    const hour = parseInt(inputHour, 10);
    const minute = parseInt(inputMinute, 10);

    return {
      hour,
      minute,
      startAt: previewTime.startAt,
    };
  };

  useEffect(() => {
    setPreviewStartTime(timeInput);
    showTimePicker();
  }, []);

  useEffect(() => {
    if (isVisible === true) {
      readOneSign(signId, {
        previewStart: previewStartTime,
      });
    }
  }, [isVisible]);

  useEffect(() => {
    if (!refreshScheduleCount) return;
    readOneSign(signId, {
      previewStart: previewStartTime,
    });
  }, [refreshScheduleCount]);

  useEffect(() => {
    readOneSign(signId, {
      previewStart: previewStartTime,
    });
  }, [previewStartTime]);

  useEffect(() => {
    const contentDurationInMS = (previewContents[videoIndex]?.endTime - previewContents[videoIndex]?.startTime) * ONE_SECOND_MS
      || DEFAULT_IMAGE_DURATION_MS;
    setPlayContentTimer(setTimeout(() => {
      playNextVideo();
    }, contentDurationInMS));

    return () => {
      if (playContentTimer) clearTimeout(playContentTimer);
    };
  }, [videoIndex]);

  useEffect(() => {
    const previewTimeFromInput = getPreviewTimeFromTimeInput(timeInput);
    setPreviewTime(previewTimeFromInput);
  }, [timeInput]);

  useEffect(() => {
    if (!sign) return;

    if (timeInput === DEFAULT_TIME_INPUT) {
      hideTimePicker();
      const currentTimeInSignTZ = DateTime.local({ zone: sign.timezone });
      setTimeInput(`${pad(currentTimeInSignTZ.hour)}:${currentTimeInSignTZ.minute > THIRTY_MINUTE_INTERVAL ? '30' : '00'}`);
    }
  }, [sign]);

  useEffect(() => {
    if (!previewTime || !sign) return;

    const previewStartTimeInPreviewTimeFormat = getPreviewTimeFromTimeInput(previewStartTime);

    const shouldRefreshSignSchedulePreview = previewStartTimeInPreviewTimeFormat.hour !== previewTime.hour || previewStartTimeInPreviewTimeFormat.minute !== previewTime.minute;
    if (shouldRefreshSignSchedulePreview) setPreviewStartTime(timeInput);

    const safeSignSchedule = sign && sign.signPreview && sign.signPreview.schedule ? sign.signPreview.schedule : [];

    const startIndex = safeSignSchedule.findIndex((preview) => {
      const startTime = DateTime.fromSeconds(preview.startTime, { zone: sign.timezone });
      return startTime.hour === previewTime.hour && startTime.minute === previewTime.minute;
    });
    const endIndex = safeSignSchedule.findIndex((preview) => {
      const startTime = DateTime.fromSeconds(preview.startTime, { zone: sign.timezone });
      const nextPreviewTime = getNextMinute(previewTime);
      return startTime.hour === nextPreviewTime.hour && startTime.minute === nextPreviewTime.minute;
    });
    const contents = safeSignSchedule.slice(startIndex, endIndex);
    setPreviewContents(contents);

    const videoIndexToSet = {
      'beginning': 0,
      'end': contents.length - 1,
    }[previewTime.startAt];
    setVideoIndex(videoIndexToSet);
  }, [previewTime, sign]);

  const videoStartTime = () => {
    return DateTime
      .fromSeconds(previewContents[videoIndex].startTime, { zone: sign?.timezone })
      .toLocaleString(DateTime.TIME_24_WITH_SECONDS);
  };

  const FILE_DICT = previewContents.reduce((acc, item) => {
    if (!acc[item.fileId] && !!sign?.signPreview?.files.find((file) => file.id === item.fileId)) {
      acc[item.fileId] = sign?.signPreview?.files.find((file) => file.id === item.fileId)!;
    }

    return acc;
  }, {} as Record<number, IFileResponse>);

  const renderBody = () => {
    const safeSignSchedule = sign && sign.signPreview && sign.signPreview.schedule ? sign.signPreview.schedule : [];
    const defaultContentPlaysInSchedule = sign && sign.signPreview && sign.signPreview.schedule && sign.defaultFile
      ? sign.signPreview.schedule.some((content) => content.id === sign.defaultFile.id)
      : false;

    return (
      <>
        {/* preview header data */}
        <div className="p-3 mb-2 bg-white shadow-sm">
          <div className="mb-2 d-flex justify-content-between">
            <h2 className="h5 mb-0">
              {isTimePickerVisible ? 'Playing at' : 'Currently Playing'} <small>{DateTime.local().toFormat('MM/dd')}</small>
            </h2>
            {(myUser?.role === SUPER_ADMIN_ROLE && sign)
            && (<Button
              variant="warning"
              size="sm"
              onClick={() => refreshOneSignSchedule(sign.id)}
            >
              {refreshOneSignScheduleIsLoading
                ? <FontAwesomeIcon icon="circle-notch" spin />
                : 'Refresh Schedule'
              }
            </Button>)}
          </div>

          <div>
            <div className="d-flex align-items-center">
              {isTimePickerVisible
                ? (<>
                  <BaseTimePicker
                    minuteIncrement={THIRTY_MINUTE_INTERVAL}
                    value={timeInput}
                    onChange={setTimeInput}
                  />
                  <span className="ps-2">- {thirtyMinutesFromPreviewTime(previewTime)}</span>
                </>)
                : (<>
                  {getReadableTimeFromPreviewTime(previewTime)} - {thirtyMinutesFromPreviewTime(previewTime)}
                  <Button
                    variant="link"
                    size="sm"
                    onClick={showTimePicker}
                  >
                    Change Time
                  </Button>
                </>)
              }
            </div>
            {(sign) && (<small>{IANA_TO_READABLE_TIMEZONES_DICT[sign.timezone] || sign.timezone} time</small>)}
          </div>
        </div>

        {/* video player & content list */}
        {readOneSignIsLoading
          ? (<div style={{ minHeight: 200 }}>
            <Loader size="2x" />
          </div>)
          : (
            <Row className="g-0">
              <Col
                xs="12"
                lg="6"
              >
                <div className="bg-white shadow-sm h-100">
                  {!previewContents.length
                    ? (<div className="p-3">Nothing Playing at this time</div>)
                    : (<>
                      <div className="bg-dark mb-3 text-white">
                        <FileWithPreview
                          ref={fileWithPreviewRef}
                          file={FILE_DICT[previewContents[videoIndex].fileId]}
                          stretchToDimensions={sign
                            ? {
                              width: sign.width,
                              height: sign.height,
                            }
                            : undefined}
                          disablePreviewModal={true}
                          disableVideoControls={true}
                        />
                      </div>
                      <div className="p-3">
                        <h3 className="h5 mb-3">
                          <strong>{previewContents[videoIndex].name}</strong> | {videoStartTime()}
                        </h3>
                        <Row>
                          <Col className="text-end">
                            <Button
                              type="button"
                              size="lg"
                              variant="link"
                              onClick={() => playPreviousVideo(true)}
                              disabled={videoIndex === 0}
                            >
                              <FontAwesomeIcon icon="backward" />
                            </Button>
                          </Col>
                          <Col className="text-center">
                            <Button
                              className="rounded-circle"
                              type="button"
                              size="lg"
                              onClick={pausePlayContent}
                            >
                              {isPaused ? <FontAwesomeIcon icon="play" /> : <FontAwesomeIcon icon="pause" />}
                            </Button>
                          </Col>
                          <Col className="text-start">
                            <Button
                              type="button"
                              size="lg"
                              variant="link"
                              onClick={() => playNextVideo(true)}
                              disabled={videoIndex >= safeSignSchedule.length}
                            >
                              <FontAwesomeIcon icon="forward" />
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </>)
                  }
                </div>
              </Col>
              <Col
                xs="12"
                lg="6"
              >
                <div className="bg-white shadow-sm h-100 mt-2 mt-lg-0">
                  <Tab.Container
                    activeKey={activeTabKey}
                    onSelect={(key) => key && setActiveTabKey(key)}
                  >
                    <Nav className="px-3 pt-3">
                      <Nav.Item>
                        <Nav.Link
                          className="p-0"
                          eventKey="summary"
                        >
                          <Button
                            variant={
                              activeTabKey === 'summary'
                                ? 'primary'
                                : 'outline-primary'
                            }
                            style={{
                              borderRadius: '0.375rem 0 0 0.375rem',
                            }}
                          >
                            Can Play
                          </Button>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          className="p-0"
                          eventKey="details"
                        >
                          <Button
                            variant={
                              activeTabKey === 'details'
                                ? 'primary'
                                : 'outline-primary'
                            }
                            style={{
                              borderRadius: '0 0.375rem 0.375rem 0',
                            }}
                          >
                            Currently Playing
                          </Button>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="summary">
                        <section className="p-3">
                          {(sign && defaultContentPlaysInSchedule) && (
                            <div className="p-2">
                              <strong>Default File</strong> <br />
                              <span className="text-muted">
                                Added { DateTime.fromISO(sign.defaultFile.createdAt).toLocaleString(DateTime.DATE_MED)}
                              </span>
                            </div>
                          )}
                          {(sign && sign.signPreview && sign.signPreview.bumper) && (<div>
                            <div className="p-2">
                              <strong>Bumper Content</strong> <br />
                              <span className="text-muted">
                                Added { DateTime.fromISO(sign.signPreview.bumper.createdAt).toLocaleString(DateTime.DATE_MED)}
                              </span>
                            </div>
                            <hr className="my-0" />
                          </div>)}
                          {(sign && sign.signPreview && sign.signPreview.schedule.find((item) => item.type === 'alert')) && (<div>
                            <div className="p-2">
                              <strong>Alert Content</strong>
                            </div>
                            <hr className="my-0" />
                          </div>)}
                          {sign && sign.signPreview && sign.signPreview.contents.map((content) => {
                            const createdAt = DateTime.fromISO(content.createdAt).toLocaleString(DateTime.DATE_MED);
                            return (<div key={`${content.id}-${content.startDatetime}`}>
                              <div className="p-2 d-flex justify-content-between align-center">
                                <div className="text-wrap">
                                  <strong>{content.name}</strong> <br />
                                  <span className="text-muted">Added {createdAt}</span>
                                </div>
                                <Button
                                  variant="link"
                                  onClick={() => routerNavigate(`/schedule/signs/${signId}/contents/${content.id}`)}
                                >
                                  <FontAwesomeIcon icon="arrow-right-from-bracket" />
                                </Button>
                              </div>
                              <hr className="my-0" />
                            </div>);
                          })}
                        </section>
                      </Tab.Pane>
                      <Tab.Pane eventKey="details">
                        <section className="p-3">
                          {previewContents.map((preview, idx) => {
                            const createdAt = DateTime.fromISO(preview.createdAt).toLocaleString(DateTime.DATE_MED);
                            return (<div
                              key={`${preview.id}-${preview.startTime}`}
                              className={classNames({
                                'bg-opacity-25 bg-primary': videoIndex === idx,
                              })}
                            >
                              <div
                                className="p-2 cursor-pointer text-wrap"
                                onClick={() => setVideoIndex(idx)}
                              >
                                <strong>{preview.name}</strong><br />
                                <span className="text-muted">Added {createdAt}&nbsp;
                                  {(preview.addedBy) && (<>
                                    by {preview.addedBy}
                                  </>)}
                                </span>
                              </div>
                              <hr className="my-0" />
                            </div>);
                          })}
                        </section>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </Col>
            </Row>
          )}
      </>
    );
  };

  return (
    <Modal
      show={isVisible}
      onHide={onHide}
      fullscreen="md-down"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Sign Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 grey-modal-body">
        {renderBody()}
      </Modal.Body>
    </Modal>
  );
};

export default SignPreview;
