/* eslint-disable camelcase */
import { DateTime } from 'luxon';
import { today0000, formatTimeZoneToReadable, durationFromNow2359 } from '@/utils/helpers';

const today12AM = today0000();
const today1159PM = durationFromNow2359({ days: 0 });

export const SIGN_CREATED_MESSAGE = 'Sign Created';
export const SIGN_UPDATED_MESSAGE = 'Sign Updated';
export const SIGN_BATCH_UPDATED_MESSAGE = 'Signs Updated';
export const SIGN_DELETED_MESSAGE = 'Sign Deleted';

export const SIGN_LOAD_ERROR = 'Unable to load Sign';
export const SIGN_CREATED_ERROR = 'Unable to create Sign';
export const SIGN_UPDATED_ERROR = 'Unable to update Sign';
export const SIGN_BATCH_UPDATED_ERROR = 'Unable to update Signs';
export const SIGN_DELETED_ERROR = 'Unable to delete Sign';

export const SIGN_REPORT_ERROR = 'Unable to generate sign report';

export const REGISTRATION_KEY_CREATED_MESSAGE = 'Registration Key added';
export const REGISTRATION_KEY_CREATED_ERROR = 'Unable to create registration key';
export const REGISTRATION_KEY_DELETED_ERROR = 'Unable to delete registration key';
export const REGISTRATION_KEY_DELETED_MESSAGE = 'Registration Key deleted';

export const EMPTY_SIGN_FORM = {
  name: '',
  activeStartTime: today12AM,
  activeEndTime:today1159PM,
  width: null,
  height: null,
  timezone: formatTimeZoneToReadable(DateTime.local().zoneName),
  aspectRatio: '',
  manufacturer: 'NextLED',
  address_1: '',
  address_2: '',
  city: '',
  state: '',
  zip: '',
  country: 'United States',
  defaultFileId: null,
  alertsEnabled: true,
};

export const DEFAULT_FILE_ORIGIN = 'default';

